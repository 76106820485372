import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import useEventBus from "../../hooks/useEventBus";
import getWindow from "../../utils/window";
import { setShippingSourcesForProduct } from "../../analytics/pageLoad";
import PropTypes from "prop-types";
import styles from "./DeliveryAndReturns.scss";
import classnamesBind from "classnames/bind";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import {
  getInStockVariants,
  allVariantsPrimarySource,
} from "../../../utils/variantUtils";
import SoldShippedHeader from "./soldShippedHeader/SoldShippedHeader";
import { getProductSourceSellerInfo } from "../../../utils/sourceSellerHelper";
import { useLiveRegion } from "../../context/liveRegion/LiveRegionContext";
import { fireAfsAnalytics } from "../../analytics/pageInteraction";

const classnames = classnamesBind.bind(styles);

const DeliveryAndReturns = ({
  shippingRestrictions,
  productId,
  variants,
  index,
  toggleShippingRestrictions,
}) => {
  const { updateLiveRegion } = useLiveRegion();
  const {
    getTranslation,
    isMixAndMatch,
    countryName,
    deliveryApiEndpoint,
    features: { showReturnsMessage },
    deliveryAndReturnsTrackingValue,
  } = useRuntimeConstants();
  const inStockVariants = getInStockVariants(variants);
  const [portalReady, setPortalReady] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const selectedOrAvailableVariants = getSelectedOrAvailableVariants(
    selectedVariant,
    inStockVariants
  );
  const sourceSellerInfo = getProductSourceSellerInfo({
    variants: selectedOrAvailableVariants,
  });
  const isSeller = !!sourceSellerInfo.sellerLabel;
  const deliveryAndReturnsLink = `${deliveryApiEndpoint}${
    deliveryAndReturnsTrackingValue
      ? `#feemsg=${encodeURIComponent(deliveryAndReturnsTrackingValue)}`
      : ""
  }`;

  const updateSelectedVariant = (selectedVariantData) => {
    const variant = selectedVariantData.variantId ? selectedVariantData : null;
    const shouldVariantUpdate =
      !isMixAndMatch || index === selectedVariantData.mixAndMatchProductIndex;

    hasSellerChanged(selectedVariant, selectedVariantData) &&
      updateLiveRegion(getTranslation("dtc_sellerchangemessage_accessibility"));

    if (shouldVariantUpdate) {
      setSelectedVariant(variant);
    }
  };

  const openShippingRestrictionsModal = (event) => {
    toggleShippingRestrictions(
      true,
      shippingRestrictions?.shippingRestrictionsIncludedCountries,
      event.target
    );
  };

  useEffect(() => {
    if (getWindow().asos.pdp.deliveryAndReturnsPortalReady) {
      setPortalReady(true);
    }
  }, []);

  useEventBus(
    "deliveryAndReturnsPortalReady",
    () => {
      setPortalReady(true);
    },
    []
  );

  useEventBus("sizeChanged", updateSelectedVariant, [
    selectedVariant,
    isMixAndMatch,
  ]);

  const renderFreeDelivery = () => (
    <div
      data-testid="deliveryAndReturns__freeDelivery"
      className={classnames(styles.row, styles.freeDelivery)}
      aria-live="polite"
    >
      <span className={styles.iconContainer}>
        <span className={`${styles.icon} product-delivery-van`} />
      </span>
      {sourceSellerInfo.isConsistent
        ? `${getTranslation(
            "delivery_information_threshold_message_qualifying_orders1"
          )}.`
        : getTranslation(
            "delivery_information_threshold_message_no_variant_selected"
          )}
    </div>
  );

  const renderReturnsLabel = () =>
    showReturnsMessage && (
      <div
        data-testid="deliveryAndReturns__returnsLabel"
        className={styles.row}
      >
        <span className={classnames(styles.iconContainer)}>
          <span className={`${styles.icon} product-returns-box`} />
        </span>
        {getTranslation(
          sourceSellerInfo.isPartnerFulfils
            ? "returns_message_partner"
            : "returns_message_asos"
        )}
      </div>
    );

  const renderTerms = () => (
    <div
      data-testid="deliveryAndReturns__terms"
      className={classnames(styles.row, styles.noIcon, styles.terms)}
    >
      <span>
        <a
          data-testid="deliveryAndReturns__termsLink"
          href={deliveryAndReturnsLink}
          target="_blank"
          aria-label={`${getTranslation(
            "delivery_and_returns_policy"
          )}. ${getTranslation("generic_opens_new_window")}.`}
          rel="noreferrer"
        >
          <span className={styles.termsLinkText} aria-hidden={true}>
            {getTranslation("delivery_and_returns_policy")}
            <span className={`${styles.icon} product-new-page-small`} />
          </span>
        </a>
      </span>
    </div>
  );

  const renderShippingRestrictions = () => {
    if (isSeller && shippingRestrictions.shippingRestrictionsForSellers) {
      return (
        <div
          data-testid="deliveryAndReturns__shippingRestrictions"
          className={classnames(styles.row, styles.shippingRestrictions)}
          aria-live="polite"
        >
          <span>
            {`${sourceSellerInfo.sellerLabel} ${getTranslation(
              "dtc_shipping_restriction_label"
            )} ${countryName}`}
          </span>
        </div>
      );
    } else if (shippingRestrictions.shippingRestrictionsVisible) {
      return (
        <div
          data-testid="deliveryAndReturns__shippingRestrictions"
          className={classnames(styles.row, styles.shippingRestrictions)}
          aria-live="polite"
        >
          <button
            data-testid="deliveryAndReturns__shippingRestrictionsButton"
            onClick={openShippingRestrictionsModal}
          >
            {shippingRestrictions.shippingRestrictionsLabel}
          </button>
        </div>
      );
    }
  };

  const sendAnalytics = () => {
    if (!selectedVariant) {
      setShippingSourcesForProduct({
        fulfillerId: allVariantsPrimarySource(variants)
          ? "primary"
          : sourceSellerInfo.sourceId?.toLowerCase(),
        sellerId: sourceSellerInfo.sellerId,
        hasShippedByLabel: !!(
          sourceSellerInfo.sellerLabel || sourceSellerInfo.sourceLabel
        ),
        productId,
        variants: inStockVariants,
      });
    }
  };

  const renderContent = () => {
    return (
      <div
        className={classnames(styles.container, {
          isMixAndMatch,
        })}
        data-testid="deliveryAndReturns__container"
      >
        <div className={classnames(styles.row, styles.shippingLabel)}>
          <SoldShippedHeader
            mixAndMatchVariants={selectedOrAvailableVariants}
            fireSellerInfoAnalytics={fireAfsAnalytics}
          />
        </div>
        {renderFreeDelivery()}
        {renderReturnsLabel()}
        {renderTerms()}
        {renderShippingRestrictions()}
      </div>
    );
  };

  sendAnalytics();

  return (
    portalReady &&
    ReactDOM.createPortal(
      renderContent(),
      getWindow().document.getElementById(
        `delivery-and-returns-portal${isMixAndMatch ? `-${index}` : ""}`
      )
    )
  );
};

const variantHasSourceOrSeller = (variant) =>
  variant?.seller || variant?.source;

const hasSellerChanged = (oldValue, newValue) => {
  return !!(
    newValue.variantId &&
    !variantHasSourceOrSeller(newValue) &&
    variantHasSourceOrSeller(oldValue)
  );
};

const getSelectedOrAvailableVariants = (selectedVariant, inStockVariants) =>
  selectedVariant ? [selectedVariant] : inStockVariants;

DeliveryAndReturns.propTypes = {
  variants: PropTypes.arrayOf(PropTypes.object).isRequired,
  shippingRestrictions: PropTypes.object,
  index: PropTypes.number,
  toggleShippingRestrictions: PropTypes.func.isRequired,
};

export default DeliveryAndReturns;
