import { logError } from "../newRelicLogger";
import getWindow from "../window";
import { aggregateOfferTemplate, ratingsTemplate } from "./schemaOrgTemplates";
import { dataMapper } from "./dataMapper";

export const createAndInsertInToPage = async ({ products, ratings }) => {
  try {
    const data = dataMapper(products, ratings);

    const splitStructuredDataElement = getWindow().document.getElementById(
      "split-structured-data"
    );

    await insertAdditionalData(splitStructuredDataElement, data);
  } catch (error) {
    logError(error, {
      action: "RenderingStructuredDataToPage",
      message: "Error while generating/adding structured data",
    });
  }
};

/* Utility functions */

const insertAdditionalData = async (element, data) => {
  const splitStructuredData = getStructuredData(element);

  if (dataHasGraphStructure(splitStructuredData)) {
    addProductDataToGraphStructure(splitStructuredData, data);
    insertStructuredDataIntoPage(element, splitStructuredData);
  } else {
    splitStructuredData.offers = aggregateOfferTemplate(data.aggregateOffer);

    if (data.ratings) {
      splitStructuredData.aggregateRating = ratingsTemplate(data.ratings);
    }

    insertStructuredDataIntoPage(element, splitStructuredData);
  }
};

const addProductDataToGraphStructure = (splitStructuredData, data) => {
  const productItem = splitStructuredData["@graph"].find(
    (graphItem) => graphItem["@type"] === "Product"
  );

  if (productItem) {
    productItem.offers = aggregateOfferTemplate(data.aggregateOffer);

    if (data.ratings) {
      productItem.aggregateRating = ratingsTemplate(data.ratings);
    }
  }
};

const dataHasGraphStructure = (splitStructuredData) => {
  return Array.isArray(splitStructuredData["@graph"]);
};

const getStructuredData = (element) => {
  try {
    return JSON.parse(element.textContent);
  } catch (error) {
    logError(error, {
      action: "getStructuredData",
      message: "Error while parsing structured data from page to JSON",
    });
  }
};

const insertStructuredDataIntoPage = (element, splitStructuredData) => {
  element.textContent = JSON.stringify(splitStructuredData);
};
