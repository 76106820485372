import { useRef, useState } from "react";
import PropTypes from "prop-types";
import classnamesBind from "classnames/bind";

import styles from "./ProductTile.scss";
import SaveForLaterButton from "../buttons/saveForLaterButton/SaveForLaterButton";
import CircleButton from "../buttons/circle/CircleButton";
import { CAROUSEL_IMAGE_SIZE, PAGE_AREAS } from "../../constants";
import Image from "../image/Image";
import saveForLater from "../../utils/saveForLater";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { useFeaturesReady } from "../../context/featuresReady/FeaturesReadyContext";
import { getFeature } from "../../utils/features";
import { useLooksProducts } from "../../context/looks/LooksProductsContext";

const classnames = classnamesBind.bind(styles);

const { MIGHT_LIKE, BUY_THE_LOOK, RECENTLY_VIEWED, OUT_OF_STOCK, MAIN } =
  PAGE_AREAS;

export default function ProductTile({
  productId,
  url,
  imageUrl,
  title,
  colourWayId,
  isMixAndMatch,
  removeFunction,
  screenHoverable,
  pageArea,
  shouldShowTitle,
  saveForLaterClass,
  children,
  productTileAriaLabel,
}) {
  const variantIdentifier = `colourWayId:${colourWayId}`;

  const blurTimer = useRef(null);
  const [shouldShowRemoveButton, setShouldShowRemoveButton] = useState(false);
  const { getTranslation } = useRuntimeConstants();
  const { areFeaturesReady } = useFeaturesReady();
  const { looksProducts } = useLooksProducts();

  const showRemoveButton = () => {
    clearTimeout(blurTimer.current);
    setShouldShowRemoveButton(true);
  };

  const hideRemoveButton = () => {
    blurTimer.current = setTimeout(() => setShouldShowRemoveButton(false));
  };

  const handleRemoveClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    removeFunction(productId, title);
  };

  const handleSaveClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    saveForLater({
      product: { id: productId },
      items: [{ productId, colourWayId }],
      pageArea,
      looksProducts,
    });
  };

  const renderImage = () => (
    <div className={styles.skeleton}>
      <Image
        additionalClasses={styles.image}
        ariaHidden={shouldShowTitle}
        url={imageUrl}
        alternateText={title}
        type={"carousel"}
        width={CAROUSEL_IMAGE_SIZE}
        loadingType={"lazy"}
      />
      {removeFunction && (shouldShowRemoveButton || !screenHoverable) && (
        <CircleButton
          handleClick={handleRemoveClick}
          additionalClasses={styles.closeButton}
          iconClass={classnames(styles.closeIcon, "product-closesmall")}
          ariaLabel={getTranslation("pdp_carousel_recently_viewed_remove_item")}
          testId="removeProduct"
        />
      )}
    </div>
  );

  const createLinkButtonElement = () => (
    <>
      <a href={url} className={styles.link} aria-label={productTileAriaLabel}>
        {renderImage()}
        {children}
      </a>
      {renderSaveForLater()}
    </>
  );

  const showSFL = () =>
    pageArea !== MIGHT_LIKE ||
    (areFeaturesReady && getFeature("pdp-sfl-ymal").isEnabled());

  const renderSaveForLater = () => {
    if (!isMixAndMatch && showSFL()) {
      return (
        <SaveForLaterButton
          handleClick={handleSaveClick}
          productTitle={title}
          variantIdentifier={variantIdentifier}
          saveForLaterClass={saveForLaterClass}
        />
      );
    }
  };

  return (
    <div
      className={styles.container}
      role="group"
      onMouseEnter={showRemoveButton}
      onMouseLeave={hideRemoveButton}
      onFocus={showRemoveButton}
      onBlur={hideRemoveButton}
    >
      {createLinkButtonElement()}
    </div>
  );
}

ProductTile.propTypes = {
  productId: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  colourWayId: PropTypes.number.isRequired,
  isMixAndMatch: PropTypes.bool,
  removeFunction: PropTypes.func,
  screenHoverable: PropTypes.bool,
  pageArea: PropTypes.oneOf([
    MIGHT_LIKE,
    BUY_THE_LOOK,
    RECENTLY_VIEWED,
    OUT_OF_STOCK,
    MAIN,
  ]),
  shouldShowTitle: PropTypes.bool,
  saveForLaterClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  productTileAriaLabel: PropTypes.string.isRequired,
};
