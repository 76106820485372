const individualProductTemplate = (data) => ({
  "@type": "IndividualProduct",
  name: data.name,
  sku: data.sku,
  color: data.color,
  image: data.image,
  brand: brandTemplate(data.brand),
});

export const brandTemplate = (data) => ({
  "@type": "Brand",
  name: data.name,
});

const sellerTemplate = () => ({
  "@type": "Organization",
  name: "ASOS",
});

export const aggregateOfferTemplate = (data) => ({
  "@type": "AggregateOffer",
  lowPrice: data.lowPrice,
  highPrice: data.highPrice,
  priceCurrency: data.currency,
  availability: data.isInStock
    ? "https://schema.org/InStock"
    : "https://schema.org/OutOfStock",
  offerCount: data.offers.length,
  offers: data.offers.map((offer) => offerTemplate(offer)),
});

const offerTemplate = (data) => ({
  "@type": "Offer",
  price: data.price,
  priceCurrency: data.currency,
  itemOffered: individualProductTemplate(data.product),
  seller: sellerTemplate(),
  availability: data.isInStock
    ? "https://schema.org/InStock"
    : "https://schema.org/OutOfStock",
  itemCondition: "https://schema.org/NewCondition",
});

export const ratingsTemplate = (ratings) => ({
  "@type": "AggregateRating",
  ratingValue: ratings.averageOverallRating,
  ratingCount: ratings.totalReviewCount,
  bestRating: 5,
  worstRating: 1,
});
